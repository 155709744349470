import React from 'react';
import Datalist from './citylist';
import Styles from './citytitle.module.css';
import {Link} from 'gatsby';
import CesiumContainer from "../../pages/cesiumContainer"
import Refresh from "../../components/refresh"

function useStickyState(defaultValue, key) {
  const [value, setValue] = React.useState(() => {
    const stickyValue = typeof window !== 'undefined' && window.localStorage.getItem(key);
    return stickyValue !== null
      ? JSON.parse(stickyValue)
      : defaultValue;
  });
  React.useEffect(() => {
    typeof window !== 'undefined' && window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);
  return [value, setValue];
}

const refreshPage = ()=>{
  window.location.reload();
}


const useStateWithLocalStorage = localStorageKey => {
  const [value, setValue] = React.useState(
    typeof window !== 'undefined' && window.localStorage.getItem(localStorageKey)
    || ''
  );

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem(localStorageKey, value);
    }
    }, [value]);
 
  return [value, setValue];
};


const App = (props) => {
  const [value, setValue] = useStateWithLocalStorage(
    'cityset1'
  );
 
  const onChange = event => setValue(event.target.value);


  return (
    <div style={{display:"flex"}}>
      <div className={Styles.cp_iptxt}>
        <h2>{props.kotei}</h2>
      </div>
      <div className={Styles.cp_iptxt3}>
        <h1>
          ⇒
        </h1>
      </div>
      <div className={Styles.cp_iptxt2}>
        <div style={{
          color: "#fff",
          backgroundColor: "white",
          background: "white",
          borderRadius: "2px 2px 2px 2px",
          padding: "5px 5px 5px 5px",
          textAlign: "center",
          cursor: "pointer",
          margin:"10px 0 10px -20px", 
          width:"100%",
          border:"inset 2px #0000ff",
          }}>
          <a href={props.kml_kmz} download>Google Earthで表示</a>
        </div>
      </div>
    </div>
  );
};

export default App;